import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: null,
  success: false,
  error: false,
};

const registerSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    startRegisterUser(state) {
      state.loading = true;
    },
    registerUserSuccessful(state) {
      state.loading = false;
      state.message = "Kayıt Başarılı!";
      state.success = true;
    },
    registerUserFailed(state, action) {      
      state.loading = false;
      state.message = action.payload;
      state.error = true;
    },
    resetRegisterFlagChange(state) {
      state.loading = false;
      state.message = null;
      state.success = false;
      state.error = false;
    },
  },
});

export const { startRegisterUser, registerUserSuccessful, registerUserFailed, resetRegisterFlagChange } =
  registerSlice.actions;

export default registerSlice.reducer;
