import { Table } from "antd";
import { Alert } from "reactstrap";
import React, { useEffect, useState } from "react";

import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";
import PageTableProvider from "../../../Components/Common/PageTableProvider";

import { useStoreOlcuGiris } from "./hooks/useStore";
import { getUretimEmirListe } from "../../../helpers/api_requests";

const TabModelSecim = ({ toggleTabId, activeTabId, tabId }) => {
  const { dispatch, setStoreUretimEmirId } = useStoreOlcuGiris();

  const [loading, setLoading] = useState(false);
  const [uretimEmirleri, setUretimEmirleri] = useState([]);
  const [error, setError] = useState(null);

  const errorMessage = useErrorMessages(error);

  const tableColumns = [
    {
      dataIndex: "uretimEmiri",
      title: "Üretim Emiri",
      align: "center",
      render: (value) => <div className="text-start">{value}</div>,
    },
    {
      dataIndex: "siparisNo",
      title: "Sipariş No",
      align: "center",
      render: (value) => <div className="text-start">{value}</div>,
    },
    {
      dataIndex: "modelNo",
      title: "Model No",
      align: "center",
      render: (value) => <div className="text-start">{value}</div>,
    },
    {
      dataIndex: "modelAdi",
      title: "Model Adı",
      align: "center",
      render: (value) => <div className="text-start">{value}</div>,
    },
  ];

  useEffect(() => {
    if (activeTabId === tabId) {
      fetchUretimEmirListesi();
    }
  }, [activeTabId, tabId]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setError(null), 3000);
    }
  }, [errorMessage]);

  const fetchUretimEmirListesi = async () => {
    try {
      setLoading(true);
      const responseData = await getUretimEmirListe();
      setUretimEmirleri(responseData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableRowClick = (event, uretimEmirId) => {
    event.preventDefault();
    dispatch(setStoreUretimEmirId(uretimEmirId));
    toggleTabId(activeTabId + 1);
  };

  return (
    <div>
      {errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

      <PageTableProvider tableData={uretimEmirleri} searchPlaceholder="Ara.." btnVisible={false}>
        {(filteredTableData, tableFooter) => (
          <Table
            bordered
            size="small"
            loading={loading}
            columns={tableColumns}
            dataSource={filteredTableData}
            footer={() => tableFooter}
            pagination={{ pageSize: 50 }}
            scroll={{ x: 800 }}
            onRow={(rowItem) => ({
              onMouseDown: (e) => handleTableRowClick(e, rowItem?.id),
              onMouseEnter: (e) => (e.currentTarget.style.cursor = "pointer"),
              onMouseLeave: (e) => (e.currentTarget.style.cursor = "default"),
            })}
          />
        )}
      </PageTableProvider>
    </div>
  );
};

export default TabModelSecim;
