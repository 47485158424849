import { ConfigProvider, message } from "antd";
import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useDisplayResize } from "../Hooks/ResizeHooks";

const PageTableProvider = ({
  children,
  tableData,
  btnText,
  btnVisible = true,
  btnOnClick,
  searchVisible = true,
  searchPlaceholder,
  customBtn = null,
}) => {
  const { isMobileView } = useDisplayResize();
  const [searchText, setSearchText] = useState("");

  if (!tableData) {
    message.warning("Tablo verileri bulunamadı! Hatayı teknik ekibe bildiriniz.");
    return null;
  }

  const filteredTableData = tableData?.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLocaleLowerCase("TR").includes(searchText.toLocaleLowerCase("TR"))
    )
  );

  const tableFooter = (
    <div className="bg-danger d-inline-block rounded text-white" style={{ padding: "3px 10px" }}>
      Toplam: {filteredTableData.length}
    </div>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#405189",
            headerColor: "#F3F6F9",
            headerBorderRadius: 5,
            colorBgContainer: "#F3F6F9",
            headerFilterHoverBg: "#F3F6F9",
            footerBg: "#F3F6F9",
            borderColor: "#B6BBC4",
          },
        },
      }}
    >
      <Row className="g-3">
       
        {searchVisible && (
          <Col sm={12} md={5}>
            <div className="search-box d-flex align-items-center">
              <input
                type="text"
                id="search-bar-0"
                className="form-control search"
                placeholder={searchPlaceholder}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </Col>
        )}
       
        {btnVisible && (
          <Col sm={12} md={7}>
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              {customBtn ? customBtn() : null}
              <Button color="primary" onClick={btnOnClick} className={isMobileView ? "w-100" : ""}>
                <i className="ri-add-circle-fill align-bottom me-2"></i>
                {btnText || "Yeni Satır Ekle"}
              </Button>
            </div>
          </Col>
        )}
      
        <Col xs={12}>{children(filteredTableData, tableFooter)}</Col>
      
      </Row>
    </ConfigProvider>
  );
};

export default PageTableProvider;
