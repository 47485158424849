import React from "react";

import SidebarCard1 from "./SidebarCard1";
import SidebarCard2 from "./SidebarCard2";
import SidebarCard3 from "./SidebarCard3";

const SidebarOlcumSayfasi = () => {
  return (
    <div className="col-3 rounded">
      <SidebarCard1 />
      <SidebarCard2 />
      <SidebarCard3 />
    </div>
  );
};

export default SidebarOlcumSayfasi;
