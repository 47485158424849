export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatPhoneNumber = (value) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 5) return phoneNumber;

  if (phoneNumberLength < 8) {
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
  }

  if (phoneNumberLength < 10) {
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
  }

  return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9, 11)}`;
};

export const hexToInt = (hex) => {
  if (!hex) {
    return 0;
  }

  if (typeof hex === "string" && hex.trim().startsWith("#")) {
    hex = hex.slice(1);
  }

  return parseInt(hex, 16);
};

export const intToHex = (int) => {
  if (!int || typeof int !== "number") {
    return "-";
  }

  let hex = int.toString(16).toUpperCase();
  hex = hex.padStart(6, "0");
  return `#${hex}`;
};
