import { Switch, Table } from "antd";
import { Alert, Label } from "reactstrap";
import React, { useEffect, useState } from "react";

import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";
import PageTableProvider from "../../../Components/Common/PageTableProvider";

import { useStoreOlcuGiris } from "./hooks/useStore";
import { getModelOlcumSecimListe } from "../../../helpers/api_requests";

const TabOlcuTabloSecim = ({ toggleTabId, activeTabId, tabId }) => {
  const { dispatch, setStoreSecimYapilanOlculer, uretimEmirId, modelOlcumId } = useStoreOlcuGiris();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(null);
  const [tumunuSec, setTumunuSec] = useState(false);

  const errorMessage = useErrorMessages(error);

  const tableColumns = [
    {
      dataIndex: "olcuAdi",
      title: "Ölçü Adı",
      align: "center",
      render: (value) => <div className="text-start">{value}</div>,
    },
    {
      dataIndex: "kritik",
      title: "Kritik",
      align: "center",
      render: (kritik) => {
        const badgeStatus = kritik ? "danger" : "primary";
        return (
          <span className={`badge bg-${badgeStatus}-subtle text-${badgeStatus}`}>{kritik ? "Kritik" : "Normal"}</span>
        );
      },
    },
    {
      dataIndex: "pom",
      title: "Pom",
      align: "center",
    },
    {
      dataIndex: "toleransArti",
      title: "Tolerans +",
      align: "center",
    },
    {
      dataIndex: "toleransEksi",
      title: "Tolerans -",
      align: "center",
    },
    {
      dataIndex: "olcuSecimi",
      title: "Ölçü Seçimi",
      align: "center",
      render: (olcuSecimi, rowItem) => {
        return (
          <div
            dir="ltr"
            className="d-flex justify-content-center align-items-center form-check form-switch form-switch-md"
          >
            <Switch
              className="me-2"
              id={`switch-${rowItem?.id}`}
              checked={olcuSecimi}
              onChange={() => handleChangeSecim(rowItem)}
            />
            <Label className="form-check-label" htmlFor={`switch-${rowItem?.id}`}>
              Seçim {olcuSecimi ? "Yapıldı" : "Yapılmadı"}
            </Label>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (activeTabId === tabId && uretimEmirId && modelOlcumId && !tableData.length) {
      fetchModelOlcumSecimListe();
    }

    // return () => {
    //   if (tableData.length) {
    //     setTableData([]);
    //   }
    // };
  }, [uretimEmirId, modelOlcumId, activeTabId, tabId]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setError(null), 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    allTrueOlcuSecimi();
  }, [tableData]);

  const fetchModelOlcumSecimListe = async () => {
    try {
      setLoading(true);
      const responseData = await getModelOlcumSecimListe(uretimEmirId, modelOlcumId);
      setTableData(responseData?.map((item) => ({ ...item, olcuSecimi: false })));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSecim = (rowItem) => {
    setTableData((pre) =>
      pre.map((item) => {
        if (item?.id === rowItem?.id) {
          return { ...item, olcuSecimi: !item?.olcuSecimi };
        }
        return item;
      })
    );
  };

  const handleNextTabClick = () => {
    const secimYapilanOlculer = tableData.filter((item) => item?.olcuSecimi === true);

    if (!secimYapilanOlculer.length) {
      setError("Lütfen En Az 1 Adet Ölçü Seçimi Yapınız!");
      return;
    }

    dispatch(setStoreSecimYapilanOlculer(secimYapilanOlculer));
    toggleTabId(activeTabId + 1);
  };

  const allTrueOlcuSecimi = () => {
    const isAllTrueOlcuSecimi = tableData.every((item) => item.olcuSecimi === true);

    if (isAllTrueOlcuSecimi) {
      setTumunuSec(true);
    } else {
      setTumunuSec(false);
    }
  };

  return (
    <div>
      {errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

      <PageTableProvider tableData={tableData} searchPlaceholder="Ara.." btnVisible={false}>
        {(filteredTableData, tableFooter) => (
          <Table
            bordered
            size="small"
            rowKey="id"
            loading={loading}
            columns={tableColumns}
            dataSource={filteredTableData}
            footer={() => (
              <div className="d-flex align-items-center justify-content-between">
                {tableFooter}
                <button
                  type="button"
                  className="ms-2 btn btn-soft-primary w-lg"
                  style={{ padding: "4px 8px" }}
                  onClick={() => {
                    setTumunuSec((pre) => !pre);
                    setTableData((pre) => pre.map((item) => ({ ...item, olcuSecimi: !tumunuSec })));
                  }}
                >
                  {tumunuSec ? "Tüm Seçimleri Kaldır" : "Tümünü Seç"}
                </button>
              </div>
            )}
            pagination={{ pageSize: 50 }}
            scroll={{ x: 800 }}
          />
        )}
      </PageTableProvider>

      <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
        <button type="button" onClick={handleNextTabClick} className="btn btn-success btn-label right nexttab">
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Renk Seçimine Geç
        </button>
      </div>
    </div>
  );
};

export default TabOlcuTabloSecim;
