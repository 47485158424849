import { postRegister } from "../../../helpers/api_requests";

// action
import { startRegisterUser, registerUserSuccessful, registerUserFailed, resetRegisterFlagChange } from "./reducer";

export const registerUser = (user) => async (dispatch) => {
  try {
    dispatch(startRegisterUser());

    await postRegister(user);

    dispatch(registerUserSuccessful());
  } catch (error) {
    console.log(error);
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
