import { Table } from "antd";
import React, { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

import StepsModal from "./StepsModal";
import PageTableProvider from "../../../Components/Common/PageTableProvider";

import { useStoreOlcuGiris } from "./hooks/useStore";
import { APP_NAME } from "../../../utils/app-constants";

const OlcuGirisi = ({ pageTitle }) => {
  const { dispatch, resetStore } = useStoreOlcuGiris();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [visibleStepsModal, setVisibleStepsModal] = useState(false);

  const tableColumns = [
    {
      dataIndex: "kod",
      title: "Kod",
      align: "center",
    },
    {
      dataIndex: "tanim",
      title: "Tanım",
      align: "center",
    },
    {
      dataIndex: "renkKodu",
      title: "Renk Kodu",
      align: "center",
      render: (renkKodu) => {
        const hexRenkKodu = intToHex(renkKodu);
        return <>{hexRenkKodu}</>;
      },
    },
    {
      dataIndex: "pasif",
      title: "Durum",
      align: "center",
      render: (pasif) => {
        const badgeStatus = pasif ? "danger" : "success";
        return (
          <span className={`badge bg-${badgeStatus}-subtle text-${badgeStatus}`}>{pasif ? "Pasif" : "Aktif"}</span>
        );
      },
    },
  ];

  const handleModalClose = () => {
    dispatch(resetStore());
    setVisibleStepsModal(false);
  };

  document.title = `${pageTitle} | ${APP_NAME}`;

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb pageTitle={pageTitle} title="Ölçü Kontrol" />

        {visibleStepsModal ? <StepsModal isOpen={visibleStepsModal} toggle={handleModalClose} /> : null}

        <Card>
          <CardBody>
            <PageTableProvider
              tableData={tableData}
              btnText="Ekle"
              searchPlaceholder="Ara.."
              btnOnClick={() => setVisibleStepsModal(true)}
            >
              {(filteredTableData, tableFooter) => (
                <Table
                  size="small"
                  loading={loading}
                  columns={tableColumns}
                  dataSource={filteredTableData}
                  footer={() => tableFooter}
                  pagination={{ pageSize: 50 }}
                  scroll={{ x: 800 }}
                />
              )}
            </PageTableProvider>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default OlcuGirisi;
