import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import RecordRenkTanimi from "./RecordRenkTanimi";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";
import PageTableProvider from "../../../Components/Common/PageTableProvider";

import { APP_NAME } from "../../../utils/app-constants";
import { intToHex } from "../../../utils/formatUtils";

import { deleteRenkTanimi, getRenkTanimlari } from "../../../helpers/api_requests";

const Renk = ({ pageTitle }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({ table: false });
  const [tableData, setTableData] = useState([]);

  const [visibleRecordModal, setVisibleRecordModal] = useState(false);

  const errorMessage = useErrorMessages(error);

  const tableColumns = [
    {
      dataIndex: "kod",
      title: "Kod",
      align: "center",
    },
    {
      dataIndex: "tanim",
      title: "Tanım",
      align: "center",
    },
    {
      dataIndex: "renkKodu",
      title: "Renk Kodu",
      align: "center",
      render: (renkKodu) => {
        const hexRenkKodu = intToHex(renkKodu);
        return <>{hexRenkKodu}</>;
      },
    },
    {
      dataIndex: "pasif",
      title: "Durum",
      align: "center",
      render: (pasif) => {
        const badgeStatus = pasif ? "danger" : "success";
        return (
          <span className={`badge bg-${badgeStatus}-subtle text-${badgeStatus}`}>{pasif ? "Pasif" : "Aktif"}</span>
        );
      },
    },
    {
      dataIndex: "islemler",
      title: "İşlemler",
      align: "center",
      render: (_, rowItem) => {
        return (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-danger btn-sm m-1 "
              onClick={() => handleDeleteRenkTanimi(rowItem)}
            >
              <i className="ri-delete-bin-fill"></i>
            </button>

            <button type="button" className="btn btn-info btn-sm m-1">
              <i className="ri-pencil-fill"></i>
            </button>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    fetchRenkTanimlari();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      message.error({ content: errorMessage, duration: 2 });
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  }, [errorMessage]);

  const fetchRenkTanimlari = async () => {
    try {
      setLoading(true);
      const responseData = await getRenkTanimlari();
      setTableData(responseData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRenkTanimi = async (requestData) => {
    try {
      setLoading(true);
      await deleteRenkTanimi(requestData);
      setTableData((pre) => pre.filter((item) => item?.id !== requestData?.id));
      message.success("İşlem Başarılı!");
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  document.title = `${pageTitle} | ${APP_NAME}`;

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb pageTitle={pageTitle} title="Sabitler" />

        {visibleRecordModal ? (
          <RecordRenkTanimi
            isOpen={visibleRecordModal}
            setTableData={setTableData}
            toggle={() => setVisibleRecordModal(false)}
          />
        ) : null}

        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="flex-grow-1">
                    <Button className="btn btn-info add-btn" onClick={() => setVisibleRecordModal(true)}>
                      Renk Ekle
                    </Button>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-3">
                      <Button className="btn btn-danger ms-auto">
                        <i className="ri-information-line me-1 align-bottom"></i>
                        Yardım
                      </Button>
                      <Button className="btn btn-soft-success ms-auto">Aktar</Button>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <PageTableProvider tableData={tableData} searchPlaceholder="Renk Tanımı Ara.." btnVisible={false}>
                  {(filteredTableData, tableFooter) => (
                    <Table
                      size="small"
                      loading={loading}
                      columns={tableColumns}
                      dataSource={filteredTableData}
                      footer={() => tableFooter}
                      pagination={{ pageSize: 50 }}
                      scroll={{ x: 800 }}
                    />
                  )}
                </PageTableProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Renk;
