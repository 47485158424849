import React from "react";
import { Card, CardBody, Progress } from "reactstrap";
import { useStoreOlcuGiris } from "./hooks/useStore";

const SidebarCard2 = () => {
  const { olcuListesi } = useStoreOlcuGiris();

  const { toplamOlcuIci, toplamOlcumDisi } = olcuListesi?.reduce(
    (acc, currItem) => {
      const olcuIci = currItem?.counter;
      const olcuDisi = currItem?.downCounter + currItem?.upCounter;

      acc.toplamOlcuIci += olcuIci;
      acc.toplamOlcumDisi += olcuDisi;

      return acc;
    },
    { toplamOlcuIci: 0, toplamOlcumDisi: 0 }
  );

  const progressValue = toplamOlcumDisi > 0 ? Math.ceil((toplamOlcuIci / toplamOlcumDisi) * 100) : 0;

  return (
    <Card className="bg-light overflow-hidden shadow-none fs-4">
      <CardBody className="rounded">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6 className="mb-0">Ölçüm İçi</h6>
          </div>
          <div className="flex-shrink-0">
            <b className="text-success">{toplamOlcuIci}</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6 className="mb-0">Ölçüm Dışı</h6>
          </div>
          <div className="flex-shrink-0">
            <b className="text-danger">{toplamOlcumDisi}</b>
          </div>
        </div>
      </CardBody>
      <div>
        <Progress value={progressValue} color="success" className="bg-danger rounded-0 progress-xl">
          {progressValue} %
        </Progress>
      </div>
    </Card>
  );
};

export default SidebarCard2;
