import { useState, useEffect } from "react";

/**
 * useErrorMessages
 * @param {object} responseError - Hata mesajlarını içeren nesne.
 * @returns {object} - Hata mesajlarını düzenleyen nesne.
 */

const useErrorMessages = (responseError) => {
  const [formattedErrorMessage, setFormattedErrorMessage] = useState(null);

  useEffect(() => {
    if (responseError) {
      if (typeof responseError === "string") {
        setFormattedErrorMessage(responseError);
      } else if (typeof responseError === "object" && responseError?.errors) {
        let formatted = "";
        const errors = responseError.errors;

        for (const key in errors) {
          if (Object.hasOwnProperty.call(errors, key)) {
            formatted += `${key}: ` + errors[key].join(" ") + " ";
          }
        }

        formatted = formatted.trim();
        setFormattedErrorMessage(formatted);
      } else {
        setFormattedErrorMessage("Lütfen teknik destek alınız!");
      }
    }

    if (!responseError && formattedErrorMessage) {
      setFormattedErrorMessage(null);
    }
  }, [responseError]);

  return formattedErrorMessage;
};

export default useErrorMessages;
