import React from "react";
import { APP_NAME } from "../../utils/app-constants";

import logoFimes from "../../assets/images/logo/fimes-verimlilik-logo-bg-light.png";

const Homepage = ({ pageTitle }) => {
  
  document.title = `${pageTitle} | ${APP_NAME}`;
  
  return (
    <div className="homepage-content">
      <div className="homepage-container">
        <img className="logo-anasayfa" src={logoFimes} alt="Fi-Mes Verimlilik" />
      </div>
    </div>
  );
};

export default Homepage;
