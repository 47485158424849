import { createSlice } from "@reduxjs/toolkit";
import { getLoggedInUser } from "../../../helpers/api_requests";

export const initialState = {
  error: "",
  success: "",
  user: getLoggedInUser() || {},
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setUserProfie(state, action) {
      state.user = action.payload;
    },
    profileSuccess(state, action) {
      state.success = action.payload.status;
      state.user = action.payload.data;
    },
    profileError(state, action) {
      state.error = action.payload;
    },
    editProfileChange(state) {
      state = { ...state };
    },
    resetProfileFlagChange() {
      return { ...initialState };
    },
  },
});

export const { setUserProfie, profileSuccess, profileError, editProfileChange, resetProfileFlagChange } =
  ProfileSlice.actions;

export default ProfileSlice.reducer;
