import React, { useEffect } from "react";
import { Card, CardBody, Table } from "reactstrap";

import { useStoreOlcuGiris } from "./hooks/useStore";

const SidebarCard1 = () => {
  const {
    dispatch,
    olcuListesi,
    setStoreOlcuListesi,
    setStoreCounterStatus,
    secimYapilanOlculer,
    activeOlcuId,
    olcumDataSayfa,
    isCounter,
    isUpCounter,
    isDownCounter,
  } = useStoreOlcuGiris();

  useEffect(() => {
    if (olcumDataSayfa) {
      regulateTableData();
    }
  }, [olcumDataSayfa]);

  useEffect(() => {
    if (activeOlcuId) {
      regulateCounterData();
    }
  }, [activeOlcuId, isCounter, isUpCounter, isDownCounter]);

  function regulateTableData() {
    const aramaYapilacakBedenArr = olcumDataSayfa?.lstBedenBilgi || [];
    const filteredData = aramaYapilacakBedenArr
      ?.filter((item) => secimYapilanOlculer.some((syo) => syo?.id === item?.olcuTanimId))
      ?.map((item) => ({
        id: item?.olcuTanimId,
        olcuAdi: item?.olcuAdi || "Tanımsız!",
        hedef: item?.olcuBilgi || 0,
        downCounter: 0,
        counter: 0,
        upCounter: 0,
      }));
    dispatch(setStoreOlcuListesi(filteredData));
  }

  function regulateCounterData() {
    const updatedOlcuListesi = olcuListesi.map((item) => {
      if (item?.id !== activeOlcuId) {
        return item;
      }

      const updatedItem = { ...item };

      if (isDownCounter) {
        updatedItem.downCounter += 1;
      } else if (isCounter) {
        updatedItem.counter += 1;
      } else if (isUpCounter) {
        updatedItem.upCounter += 1;
      }

      return updatedItem;
    });

    dispatch(setStoreOlcuListesi(updatedOlcuListesi));
    dispatch(setStoreCounterStatus({ isDownCounter: false, isCounter: false, isUpCounter: false }));
  }

  return (
    <Card>
      <CardBody className="p-0">
        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0">
            <thead className="bg-info-subtle text-dark">
              <tr>
                <th scope="col">Ölçü Tanım</th>
                <th scope="col">Hedef</th>
                <th scope="col">{"<"}</th>
                <th scope="col">{"x"}</th>
                <th scope="col">{">"}</th>
              </tr>
            </thead>
            <tbody className="bg-light">
              {olcuListesi.length
                ? olcuListesi.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        className={`${
                          activeOlcuId && activeOlcuId === item?.id ? "table-active bg-success-subtle text-dark" : ""
                        }`}
                      >
                        <td>{item?.olcuAdi}</td>
                        <td>{item?.hedef}</td>
                        <td>{item?.downCounter}</td>
                        <td>{item?.counter}</td>
                        <td>{item?.upCounter}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>

          {!olcuListesi.length ? (
            <div className="text-center my-4 fs-5 text-uppercase text-danger">Veri Mevcut Değil</div>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
};

export default SidebarCard1;
