import React from "react";
import { Navigate } from "react-router-dom";

import Homepage from "../pages/Homepage";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Renk from "../pages/Sabitler/Renk";
import OlcuGirisi from "../pages/OlcuKontrol/OlcuGirisi";
// import UserProfile from "../pages/Authentication/user-profile";

const authProtectedRoutes = [
  { path: "/homepage"     , component: <Homepage pageTitle="Anasayfa" /> },
  { path: "/renk-tanim"   , component: <Renk pageTitle="Renk Tanım" /> },
  { path: "/olcu-girisi"  , component: <OlcuGirisi pageTitle="Ölçü Girişi" /> },

  // { path: "/profile", component: <UserProfile /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/homepage" />,
  },
  { path: "*", component: <Navigate to="/homepage" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
