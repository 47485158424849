import React from "react";
import { Card, CardBody, Progress } from "reactstrap";
import { useStoreOlcuGiris } from "./hooks/useStore";

const SidebarCard3 = () => {
  const { kontrolSayisi } = useStoreOlcuGiris();
  return (
    <Card className="bg-light overflow-hidden shadow-none fs-4">
      <CardBody className="rounded">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6 className="mb-0">Toplam Kontrol Sayısı</h6>
          </div>
          <div className="flex-shrink-0">
            <b className="text-success">{kontrolSayisi}</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6 className="mb-0">Hedef Kontrol Sayısı</h6>
          </div>
          <div className="flex-shrink-0">
            <b className="text-info">180</b>
          </div>
        </div>
      </CardBody>
      <div>
        <Progress
          color="success"
          className="bg-info rounded-0 progress-xl"
          value={Math.ceil((kontrolSayisi / 180) * 100)}
        >
          {Math.ceil((kontrolSayisi / 180) * 100)} %
        </Progress>
      </div>
    </Card>
  );
};

export default SidebarCard3;
