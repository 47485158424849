export const LOGIN    = "/Authentication/SignIn";
export const REGISTER = "/Authentication/SignUp";

const TANIMLAR = "/Tanim";
export const GET_RENK_TANIMLARI = TANIMLAR + "/RenkListe";
export const POST_RENK_TANIMI   = TANIMLAR + "/RenkKaydet";
export const DELETE_RENK_TANIMI = TANIMLAR + "/RenkSil";

const OLCU = "/Olcu";
export const URETIM_EMIR_LISTE       = OLCU + "/UretimEmirListe";
export const MODEL_OLCUM_LISTE       = OLCU + "/ModelOlcumListe";
export const MODEL_OLCUM_SECIM_LISTE = OLCU + "/ModelOlcumSecimListe";
export const RENK_LISTE              = OLCU + "/RenkListe";
export const BEDEN_LISTE             = OLCU + "/BedenListe";
export const OLCUM_DATA_SAYFA        = OLCU + "/OlcumDataSayfa";
export const OLCUM_KAYDET            = OLCU + "/OlcumKaydet";
export const OLCUM_TIP               = OLCU + "/ModelOlcuTip";