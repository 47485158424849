import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: false,
  success: false,
  loading: false,
  isUserLogout: false,
  message: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    startLogin(state) {
      state.loading = true;
    },
    apiError(state, action) {
      state.error = true;
      state.loading = false;
      state.message = action.payload;
    },
    loginSuccess(state) {
      state.loading = false;
      state.success = true;
      state.message = "Giriş Başarılı!";
    },
    logoutUserSuccess(state) {
      state.isUserLogout = true;
    },
    reset_login_flag() {
      return { ...initialState };
    },
  },
});

export const { startLogin, apiError, loginSuccess, logoutUserSuccess, reset_login_flag } = loginSlice.actions;

export default loginSlice.reducer;
