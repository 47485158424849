import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useStoreOlcuGiris } from "./hooks/useStore";

const HeaderOlcumSayfasi = ({ totalTime }) => {
  const { surekliBeden, surekliRenk, renk, beden, olcuTipi } = useStoreOlcuGiris();

  return (
    <Card>
      <CardBody className="bg-light fs-5 rounded">
        <Row className="fs-10 g-2">
          <Col lg={9}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-shape-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Model Adı :</p>
                  <h5 className="mb-0 text-uppercase">Model Tanımı - 1</h5>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-timer-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Toplam Süre :</p>
                  <h5 className="mb-0 text-uppercase">{totalTime}</h5>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={6}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-user-settings-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Kontrolcü :</p>
                  <h5 className="mb-0">Ahmet Ekmel</h5>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={6}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-file-list-2-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Sipariş No / Üretim Emri :</p>
                  <h5 className="mb-0">11111 / 22222</h5>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded position-relative">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-palette-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Renk :</p>
                  <h5 className="mb-0">{renk}</h5>
                </div>
              </div>

              <span
                className={`position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle p-1 ${
                  surekliRenk ? "bg-success" : "bg-danger"
                }`}
              >
                <span className="visually-hidden"></span>
              </span>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded position-relative">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-body-scan-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Beden :</p>
                  <h5 className="mb-0 text-uppercase">{beden}</h5>
                </div>
              </div>
              <span
                className={`position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle p-1 ${
                  surekliBeden ? "bg-success" : "bg-danger"
                }`}
              >
                <span className="visually-hidden"></span>
              </span>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-ruler-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Ölçü Birimi :</p>
                  <h5 className="mb-0">cm</h5>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="p-2 border border-dashed bg-primary-subtle rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-layout-grid-line"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-1">Ölçü Tipi :</p>
                  <h5 className="mb-0">{olcuTipi}</h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default HeaderOlcumSayfasi;
