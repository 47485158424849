import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import TabModelSecim from "./TabModelSecim";
import TabOlcuSecim from "./TabOlcuSecim";
import TabOlcuTabloSecim from "./TabOlcuTabloSecim";
import TabRenkSecim from "./TabRenkSecim";
import TabBedenSecim from "./TabBedenSecim";
import TabOlcumSayfasi from "./TabOlcumSayfasi";
import { useStoreOlcuGiris } from "./hooks/useStore";
import SidebarOlcumSayfasi from "./SidebarOlcumSayfasi";
import TabOlcuTipSecim from "./TabOlcuTipSecim";

const TABS = [
  {
    tabId: 1,
    key: "model-secim",
    title: "Üretim Emri/Model Seçim",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabModelSecim toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
  {
    tabId: 2,
    key: "olcu-secim",
    title: "Ölçü Seçim",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabOlcuSecim toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
  {
    tabId: 3,
    key: "olcu-tip-secim",
    title: "Ölçü Tip Seçim",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabOlcuTipSecim toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
  {
    tabId: 4,
    key: "olcu-tablo-secim",
    title: "Ölçü Tablo Seçim",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabOlcuTabloSecim toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
  {
    tabId: 5,
    key: "renk-secim",
    title: "Renk Seçim",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabRenkSecim toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
  {
    tabId: 6,
    key: "beden-secim",
    title: "Beden Seçim",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabBedenSecim toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
  {
    tabId: 7,
    key: "olcum-sayfasi",
    title: "Ölçüm Sayfası",
    component: (toggleTabId, activeTabId, tabId) => (
      <TabOlcumSayfasi toggleTabId={toggleTabId} activeTabId={activeTabId} tabId={tabId} />
    ),
  },
];

const TABS_LAST_ITEM_TAB_ID = TABS[TABS.length - 1].tabId;

const StepsModal = ({ isOpen, toggle }) => {
  const {
    dispatch,
    visibleSidebarOlcumSayfasi,
    setStoreSidebarOlcumSayfasiVisible,
    setStoreHeaderOlcumSayfasiVisible,
  } = useStoreOlcuGiris();

  const [activeTabId, setActiveTabId] = useState(1);
  // const [tabHistory, setTabHistory] = useState([1]);
  const [activeTabTitle, setActiveTabTitle] = useState(TABS[0].title);

  useEffect(() => {
    if (activeTabId === TABS_LAST_ITEM_TAB_ID) {
      dispatch(setStoreSidebarOlcumSayfasiVisible(true));
      dispatch(setStoreHeaderOlcumSayfasiVisible(true));
    } else {
      dispatch(setStoreSidebarOlcumSayfasiVisible(false));
      dispatch(setStoreHeaderOlcumSayfasiVisible(false));
    }
  }, [activeTabId]);

  function toggleTabId(tabId) {
    if (activeTabId !== tabId) {
      // const modifiedHistory = [...tabHistory, tabId];

      const activeTabTitle = TABS.find((item) => item.tabId === tabId).title;

      if (tabId >= 1 && tabId <= TABS_LAST_ITEM_TAB_ID) {
        setActiveTabId(tabId);
        // setTabHistory(modifiedHistory);
        setActiveTabTitle(activeTabTitle);
      }
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-fullscreen">
      <ModalHeader className="modal-title" toggle={toggle}>
        {activeTabTitle}
      </ModalHeader>
      <ModalBody>
        <Form className="form-steps">
          <div className="step-arrow-nav mb-4">
            <Nav className="nav-pills custom-nav nav-justified" role="tablist">
              {TABS.map((tabItem, index) => {
                let previousTabItemId = null;

                if (index === 0) {
                  previousTabItemId = -1;
                } else {
                  previousTabItemId = TABS[index - 1].tabId;
                }

                return (
                  <NavItem key={tabItem.key}>
                    <NavLink
                      href="#"
                      id={`tab-${tabItem.key}`}
                      className={classnames({
                        active: activeTabId === tabItem.tabId,
                        done: activeTabId <= TABS_LAST_ITEM_TAB_ID && activeTabId > previousTabItemId,
                      })}
                    >
                      {tabItem.title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </div>

          <div className="row">
            <TabContent activeTab={activeTabId} className={`col-${visibleSidebarOlcumSayfasi ? "9" : "12"}`}>
              {TABS.map((tabItem) => {
                return (
                  <TabPane key={tabItem.key} id={tabItem.key} tabId={tabItem.tabId}>
                    {tabItem.component(toggleTabId, activeTabId, tabItem.tabId)}
                  </TabPane>
                );
              })}
            </TabContent>

            {visibleSidebarOlcumSayfasi ? <SidebarOlcumSayfasi /> : null}
          </div>
        </Form>
      </ModalBody>
      <div className="modal-footer">
        <Link to="#" color="light" onClick={toggle} className="btn btn-link link-success fw-medium">
          <i className="ri-close-line me-1 align-middle" />
          Kapat
        </Link>
      </div>
    </Modal>
  );
};

export default StepsModal;
