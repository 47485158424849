import { APIClient } from "./api_interceptor";

import * as url from "./url_helper";

const api = new APIClient();

export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const postLogin    = (data) => api.create(url.LOGIN, data);
export const postRegister = (data) => api.create(url.REGISTER, data);

//* Tanımlar
export const getRenkTanimlari = ()     => api.get(url.GET_RENK_TANIMLARI);
export const postRenkTanimi   = (data) => api.create(url.POST_RENK_TANIMI, data);
export const deleteRenkTanimi = (data) => api.create(url.DELETE_RENK_TANIMI, data);

//* Ölçü
export const getUretimEmirListe      = ()                                    => api.get(url.URETIM_EMIR_LISTE);
export const getModelOlcumListe      = (uretimEmirId)                        => api.get(`${url.MODEL_OLCUM_LISTE}/${uretimEmirId}`);
export const getOlcumTipleri         = (uretimEmirId)                        => api.get(`${url.OLCUM_TIP}/${uretimEmirId}`);
export const getModelOlcumSecimListe = (uretimEmirId, modelOlcumId)          => api.get(`${url.MODEL_OLCUM_SECIM_LISTE}/${uretimEmirId}/${modelOlcumId}`);
export const getRenkListe            = (uretimEmirId)                        => api.get(`${url.RENK_LISTE}/${uretimEmirId}`);
export const getBedenListe           = (uretimEmirId)                        => api.get(`${url.BEDEN_LISTE}/${uretimEmirId}`);
export const getOlcumDataSayfa       = (uretimEmirId, modelOlcumId, bedenId) => api.get(`${url.OLCUM_DATA_SAYFA}/${uretimEmirId}/${modelOlcumId}/${bedenId}`);
export const postOlcumKaydet         = (data)                                => api.create(url.OLCUM_KAYDET, data);