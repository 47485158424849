import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from "reactstrap";

import ParticlesAuth from "./ParticlesAuth";
import withRouter from "../../Components/Common/withRouter";
import useErrorMessages from "../../Components/Hooks/ErrorMessageHooks";

import { APP_NAME } from "../../utils/app-constants";
import { loginUser, resetLoginFlag } from "../../slices/thunks";

import logoLightFiMes from "../../assets/images/logo/fimes-verimlilik-logo.png";

const Login = (props) => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  const [visiblePassword, setVisiblePassword] = useState(false);

  const { error, loading } = useSelector(loginpageData);

  const errorMessage = useErrorMessages(error);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: process.env.NODE_ENV === "development" ? "ahmet.yesilyurt@serkonteknoloji.com" : "",
      password: process.env.NODE_ENV === "development" ? "Serkon123" : "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Lütfen e-posta giriniz!"),
      password: Yup.string().min(6, "Şifreniz en az 6 haneli olmalı!").required("Lütfen şifre giriniz!"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 8000);
    }
  }, [dispatch, error]);

  document.title = `Giriş Yap | ${APP_NAME}`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLightFiMes} alt="" height="80" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Hoş Geldiniz!</h5>
                      <p className="text-muted">Uygulamaya devam etmek için giriş yapın.</p>
                    </div>

                    {errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            E-posta
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="E-posta giriniz.."
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          {/* <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Şifremi unuttum
                            </Link>
                          </div> */}
                          <Label className="form-label" htmlFor="password-input">
                            Şifre
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={visiblePassword ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Şifre giriniz.."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            <button
                              type="button"
                              id="password-addon"
                              onClick={() => setVisiblePassword((pre) => !pre)}
                              className={`btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted ${
                                validation.errors.password && validation.touched.password ? "me-3" : ""
                              }`}
                            >
                              <i className={`ri-eye-${visiblePassword ? "off-" : ""}fill align-middle`}></i>
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button color="success" className="btn btn-success w-100" type="submit" disabled={loading}>
                            Giriş {loading ? "Yapılıyor.." : "Yap"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Henüz bir hesabınız yok mu?{" "}
                    <Link to="/register" className="fw-semibold text-primary text-decoration-underline">
                      {" "}
                      Kayıt Ol{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
