import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  resetStore,
  setStoreRenk,
  setStoreBeden,
  setStoreOlcuListesi,
  setStoreModelOlcumId,
  setStoreOlcuTipSecim,
  setStoreUretimEmirId,
  setStoreActiveOlcuId,
  setStoreKontrolSayisi,
  setStoreCounterStatus,
  setStoreOlcumDataSayfa,
  setStoreSecimYapilanOlculer,
  setStoreSurekliStatusTabRenkSecim,
  setStoreSurekliStatusTabBedenSecim,
  setStoreSidebarOlcumSayfasiVisible,
  setStoreHeaderOlcumSayfasiVisible,
} from "../../../../slices/olcu/olcu-giris/reducer";

const useStoreOlcuGiris = () => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state.OlcuGiris;
  const storeOlcuGirisi = createSelector(selectLayoutState, (state) => ({
    uretimEmirId               : state.tabs.tab_modelSecim.uretimEmirId,
    modelOlcumId               : state.tabs.tab_olcuSecim.modelOlcumId,
    secimYapilanOlculer        : state.tabs.tab_olcuTabloSecim.secimYapilanOlculer,
    renk                       : state.tabs.tab_renkSecim.renk,
    renkId                     : state.tabs.tab_renkSecim.renkId,
    surekliRenk                : state.tabs.tab_renkSecim.surekli,
    beden                      : state.tabs.tab_bedenSecim.beden,
    bedenId                    : state.tabs.tab_bedenSecim.bedenId,
    surekliBeden               : state.tabs.tab_bedenSecim.surekli,
    kontrolSayisi              : state.tabs.tab_olcumSayfasi.kontrolSayisi,
    activeOlcuId               : state.tabs.tab_olcumSayfasi.activeOlcuId,
    olcumDataSayfa             : state.tabs.tab_olcumSayfasi.olcumDataSayfa,
    olcuTipId                  : state.tabs.tab_olcuTipSecim.olcuTipId,
    olcuTipi                   : state.tabs.tab_olcuTipSecim.olcuTipi,
    isDownCounter              : state.tabs.tab_olcumSayfasi.isDownCounter,
    isCounter                  : state.tabs.tab_olcumSayfasi.isCounter,
    isUpCounter                : state.tabs.tab_olcumSayfasi.isUpCounter,
    olcuListesi                : state.sidebarOlcumSayfasi.data_Card1.olcuListesi,
    visibleSidebarOlcumSayfasi : state.sidebarOlcumSayfasi.visible,
    visibleHeaderOlcumSayfasi  : state.headerOlcumSayfasi.visible,
  }));

  const { 
    uretimEmirId, 
    modelOlcumId, 
    olcuTipId,
    olcuTipi,
    secimYapilanOlculer, 
    renk, 
    renkId, 
    surekliRenk, 
    beden, 
    bedenId, 
    surekliBeden, 
    kontrolSayisi,
    activeOlcuId, 
    visibleSidebarOlcumSayfasi,
    visibleHeaderOlcumSayfasi,
    olcumDataSayfa,
    isDownCounter,
    isCounter,
    isUpCounter,
    olcuListesi,
  } = useSelector(storeOlcuGirisi);

  return {
    dispatch,
    uretimEmirId,
    modelOlcumId,
    olcuTipId,
    olcuTipi,
    secimYapilanOlculer,
    renk,
    renkId,
    surekliRenk,
    beden,
    bedenId,
    surekliBeden,
    kontrolSayisi,
    activeOlcuId,
    visibleSidebarOlcumSayfasi,
    visibleHeaderOlcumSayfasi,
    olcumDataSayfa,    
    isDownCounter,
    isCounter,
    isUpCounter,
    olcuListesi,
    resetStore,
    setStoreRenk,
    setStoreBeden,
    setStoreOlcuListesi,
    setStoreModelOlcumId,
    setStoreOlcuTipSecim,
    setStoreUretimEmirId,
    setStoreActiveOlcuId,
    setStoreKontrolSayisi,
    setStoreCounterStatus,
    setStoreOlcumDataSayfa,
    setStoreSecimYapilanOlculer,
    setStoreSurekliStatusTabRenkSecim,
    setStoreSurekliStatusTabBedenSecim,
    setStoreSidebarOlcumSayfasiVisible,
    setStoreHeaderOlcumSayfasiVisible,
  };
};

export { useStoreOlcuGiris };
