import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

import ParticlesAuth from "./ParticlesAuth";

import useErrorMessages from "../../Components/Hooks/ErrorMessageHooks";

import { APP_NAME } from "../../utils/app-constants";
import { formatPhoneNumber } from "../../utils/formatUtils";

import { registerUser, resetRegisterFlag } from "../../slices/thunks";

import logoLightFiMes from "../../assets/images/logo/fimes-verimlilik-logo.png";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState({ password: false, confirm_password: false });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      userName: "",
      phoneNumber: "",
      password: "",
      confirm_password: "",
      picture: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Lütfen e-posta giriniz!"),
      userName: Yup.string().required("Lütfen kullanıcı adı giriniz!"),
      phoneNumber: Yup.string()
        .matches(/^(0(\d{3})-(\d{3})-(\d{2})-(\d{2}))$/, "Telefon numaranızı yazarken başına 0 (sıfır) ekleyiniz!")
        .max(14, "11 haneli telefon numaranızı giriniz!")
        .min(14, "11 haneli telefon numaranızı giriniz!"),
      password: Yup.string().min(6, "Şifre en az 6 haneli olmalı!").required("Lütfen şifre giriniz"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Şifreler eşleşmiyor!")
        .required("Lütfen şifre tekrarını giriniz!"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    validation.setFieldValue("phoneNumber", formattedPhoneNumber);
  };

  const selectLayoutState = (state) => state.Account;
  const registerdatatype = createSelector(selectLayoutState, (account) => ({
    success: account.success,
    error: account.error,
    loading: account.loading,
    message: account.message,
  }));

  const { error, success, loading, message } = useSelector(registerdatatype);

  const errorMessage = useErrorMessages(message);

  useEffect(() => {
    if (success) {
      setTimeout(() => history("/login"), 3000);
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 3000);
  }, [dispatch, success, error, history]);

  document.title = `Kayıt Ol | ${APP_NAME}`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLightFiMes} alt="" height="80" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Yeni Hesap Oluştur</h5>
                    </div>

                    {error && errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                        {success ? (
                          <>
                            {toast("Giriş Sayfasına Yönlendiriliyorsunuz..", {
                              position: "top-right",
                              hideProgressBar: false,
                              className: "bg-success text-white",
                              progress: undefined,
                              toastId: "",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                            <Alert color="success">
                              Kayıt işleminiz gerçekleşti. Giriş sayfasına yönlendiriliyorsunuz..
                            </Alert>
                          </>
                        ) : null}

                        <div className="mb-3">
                          <Label htmlFor="useremail" className="form-label">
                            E-posta <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="E-posta giriniz.."
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userName" className="form-label">
                            Kullanıcı Adı <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="userName"
                            type="text"
                            placeholder="Kullanıcı adı giriniz.."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.userName || ""}
                            invalid={validation.touched.userName && validation.errors.userName ? true : false}
                          />
                          {validation.touched.userName && validation.errors.userName ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.userName}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="phoneNumber" className="form-label">
                            Telefon
                          </Label>
                          <Input
                            name="phoneNumber"
                            type="text"
                            placeholder="ÖRN: 0505-233-33-33"
                            onChange={handlePhoneNumberChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phoneNumber || ""}
                            invalid={validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false}
                          />
                          {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.phoneNumber}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Label htmlFor="password" className="form-label">
                          Şifre <span className="text-danger">*</span>
                        </Label>
                        <div className="position-relative mb-3">
                          <Input
                            name="password"
                            type={visible.password ? "text" : "password"}
                            placeholder="Şifre giriniz.."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}

                          <button
                            type="button"
                            id="password-addon"
                            onClick={() => setVisible((pre) => ({ ...pre, password: !pre.password }))}
                            className={`btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted ${
                              validation.errors.password && validation.touched.password ? "me-3" : ""
                            }`}
                          >
                            <i className={`ri-eye-${visible.password ? "off-" : ""}fill align-middle`}></i>
                          </button>
                        </div>

                        <Label htmlFor="confirm_password" className="form-label">
                          Şifre Tekrarı <span className="text-danger">*</span>
                        </Label>
                        <div className="position-relative mb-2">
                          <Input
                            name="confirm_password"
                            type={visible.confirm_password ? "text" : "password"}
                            placeholder="Şifre tekrarını giriniz.."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                            }
                          />
                          {validation.touched.confirm_password && validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                          <button
                            type="button"
                            id="password-addon"
                            onClick={() => setVisible((pre) => ({ ...pre, confirm_password: !pre.confirm_password }))}
                            className={`btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted ${
                              validation.errors.confirm_password && validation.touched.confirm_password ? "me-3" : ""
                            }`}
                          >
                            <i className={`ri-eye-${visible.confirm_password ? "off-" : ""}fill align-middle`}></i>
                          </button>
                        </div>

                        <div className="mt-4">
                          <button className="btn btn-success w-100" type="submit" disabled={loading}>
                            Kayıt {loading ? "Yapılıyor.." : "Ol"}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Hesabınız var mı?{" "}
                    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                      Giriş Yap
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
