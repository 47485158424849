import { Table } from "antd";
import { Alert } from "reactstrap";
import React, { useEffect, useState } from "react";

import PageTableProvider from "../../../Components/Common/PageTableProvider";
import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";

import { useStoreOlcuGiris } from "./hooks/useStore";
import { getOlcumTipleri } from "../../../helpers/api_requests";

const TabOlcuTipSecim = ({ toggleTabId, activeTabId, tabId }) => {
  const { dispatch, uretimEmirId, setStoreOlcuTipSecim } = useStoreOlcuGiris();

  const [loading, setLoading] = useState(false);
  const [olcuTipleri, setOlcuTipleri] = useState([]);
  const [error, setError] = useState(null);

  const errorMessage = useErrorMessages(error);

  const tableColumns = [
    {
      dataIndex: "tanim",
      title: "Tanım",
      align: "center",
    },
  ];

  useEffect(() => {
    if (activeTabId === tabId && uretimEmirId) {
      fetcModelOlcumListe();
    }
  }, [uretimEmirId, activeTabId, tabId]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setError(null), 3000);
    }
  }, [errorMessage]);

  const fetcModelOlcumListe = async () => {
    try {
      setLoading(true);
      const responseData = await getOlcumTipleri(uretimEmirId);

      setOlcuTipleri(responseData);

      if (isOneItem(responseData)) {
        const olcumTipi = responseData[0];
        nextTab(olcumTipi);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isOneItem = (data) => {
    return data && typeof data === "object" && data?.length === 1;
  };

  const handleTableRowClick = (event, olcumTipi) => {
    event.preventDefault();
    nextTab(olcumTipi);
  };

  const nextTab = (olcumTipi) => {
    dispatch(setStoreOlcuTipSecim(olcumTipi));
    toggleTabId(activeTabId + 1);
  };

  return (
    <div>
      {errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

      <PageTableProvider tableData={olcuTipleri} searchPlaceholder="Ara.." btnVisible={false}>
        {(filteredTableData, tableFooter) => (
          <Table
            bordered
            size="small"
            loading={loading}
            columns={tableColumns}
            dataSource={filteredTableData}
            footer={() => tableFooter}
            pagination={{ pageSize: 50 }}
            onRow={(rowItem) => ({
              onMouseDown: (e) => handleTableRowClick(e, rowItem),
              onMouseEnter: (e) => (e.currentTarget.style.cursor = "pointer"),
              onMouseLeave: (e) => (e.currentTarget.style.cursor = "default"),
            })}
          />
        )}
      </PageTableProvider>
    </div>
  );
};

export default TabOlcuTipSecim;
