import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Ölçü
import OlcuGirisReducer from "./olcu/olcu-giris/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  OlcuGiris: OlcuGirisReducer,
});

export default rootReducer;
