import { postLogin } from "../../../helpers/api_requests";
import { setUserProfie } from "../profile/reducer";

import { startLogin, loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from "./reducer";

export const loginUser = (user, history) => async (dispatch) => {
  try {
    dispatch(startLogin());

    const requestData = {
      Email: user.email,
      Password: user.password,
    };

    const response = await postLogin(requestData);

    sessionStorage.setItem("authUser", JSON.stringify({ ...response, email: requestData.Email }));
    dispatch(loginSuccess());
    dispatch(setUserProfie({ email: requestData.Email }));
    history("/homepage");
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
