import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();

  const [iscurrentState, setIscurrentState] = useState("OlcuKontrol");
  const [isOlcuKontrol, setIsOlcuKontrol] = useState(false);
  const [isSabitler, setIsSabitler] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "OlcuKontrol") {
      setIsOlcuKontrol(false);
    }
    if (iscurrentState !== "Sabitler") {
      setIsSabitler(false);
    }
  }, [history, iscurrentState, isOlcuKontrol, isSabitler]);

  const menuItems = [
    {
      label: "İşlemler",
      isHeader: true,
    },
    {
      id: "olcu-kontrol",
      label: "Ölçü Kontrol",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isOlcuKontrol,
      click: function (e) {
        e.preventDefault();
        setIsOlcuKontrol(!isOlcuKontrol);
        setIscurrentState("OlcuKontrol");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "olcu-girisi",
          label: "Ölçü Girişi",
          link: "/olcu-girisi",
          parentId: "olcu-kontrol",
        },
      ],
    },

    {
      label: "Tanımlar",
      isHeader: true,
    },
    {
      id: "sabitler",
      label: "Sabitler",
      icon: " ri-attachment-line",
      link: "/#",
      stateVariables: isSabitler,
      click: function (e) {
        e.preventDefault();
        setIsSabitler(!isSabitler);
        setIscurrentState("Sabitler");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "renk-tanim",
          label: "Renk Tanım",
          link: "/renk-tanim",
          parentId: "sabitler",
        },
      ],
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
