import { Switch, Table } from "antd";
import { Alert, Label } from "reactstrap";
import React, { useEffect, useState } from "react";

import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";
import PageTableProvider from "../../../Components/Common/PageTableProvider";

import { useStoreOlcuGiris } from "./hooks/useStore";
import { getRenkListe } from "../../../helpers/api_requests";

const TabRenkSecim = ({ toggleTabId, activeTabId, tabId }) => {
  const {
    dispatch,
    uretimEmirId,
    bedenId,
    surekliBeden,
    surekliRenk,
    setStoreRenk,
    setStoreSurekliStatusTabRenkSecim,
  } = useStoreOlcuGiris();

  const [loading, setLoading] = useState(false);
  const [renkler, setRenkler] = useState([]);
  const [error, setError] = useState(null);

  const errorMessage = useErrorMessages(error);

  const tableColumns = [
    {
      dataIndex: "tanim",
      title: "Tanım",
      align: "center",
    },
  ];

  useEffect(() => {
    if (activeTabId === tabId && uretimEmirId && !renkler.length) {
      fetchRenkListe();
    }
  }, [uretimEmirId, activeTabId, tabId]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setError(null), 3000);
    }
  }, [errorMessage]);

  const fetchRenkListe = async () => {
    try {
      setLoading(true);
      const responseData = await getRenkListe(uretimEmirId);
      setRenkler(responseData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableRowClick = (event, renk) => {
    event.preventDefault();
    dispatch(setStoreRenk({ renkId: renk?.id, renk: renk?.tanim }));

    if (surekliBeden && bedenId) {
      toggleTabId(activeTabId + 2);
    } else {
      toggleTabId(activeTabId + 1);
    }
  };

  const handlePreviousTabClick = () => {
    toggleTabId(activeTabId - 1);
  };

  const handleChangeSurekli = (checked) => {
    dispatch(setStoreSurekliStatusTabRenkSecim(checked));
  };

  return (
    <div>
      {errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

      <PageTableProvider tableData={renkler} searchPlaceholder="Ara.." btnVisible={false}>
        {(filteredTableData, tableFooter) => (
          <Table
            bordered
            size="small"
            loading={loading}
            columns={tableColumns}
            dataSource={filteredTableData}
            footer={() => tableFooter}
            pagination={false}
            onRow={(rowItem) => ({
              onMouseDown: (e) => handleTableRowClick(e, rowItem),
              onMouseEnter: (e) => (e.currentTarget.style.cursor = "pointer"),
              onMouseLeave: (e) => (e.currentTarget.style.cursor = "default"),
            })}
          />
        )}
      </PageTableProvider>

      <div className="form-check mt-2">
        <Switch id="surekli-renk" checked={surekliRenk} onChange={handleChangeSurekli} />
        <Label className="ms-2 fs-5 form-check-label" htmlFor="surekli-renk">
          Sürekli
        </Label>
      </div>

      <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
        <button type="button" onClick={handlePreviousTabClick} className="btn btn-success btn-label left nexttab">
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
          Ölçü Tablo Seçimine Geri Dön
        </button>
      </div>
    </div>
  );
};

export default TabRenkSecim;
