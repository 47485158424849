import * as Yup from "yup";
import { message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, FormFeedback, Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";

import { hexToInt } from "../../../utils/formatUtils";

import { postRenkTanimi } from "../../../helpers/api_requests";

const RecordRenkTanimi = ({ toggle, isOpen, isEdit = false, editData, setTableData }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const errorMessage = useErrorMessages(error);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }, [errorMessage]);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        ...values,
        Id: isEdit ? editData?.id : 0,
        RenkKodu: hexToInt(values?.RenkKodu),
      };

      let responseBody;

      if (isEdit) {
        //* [TODO]: Güncelleme İşlemi
      } else {
        responseBody = await postRenkTanimi(requestBody);

        const newItem = {
          id: responseBody?.extra?.id,
          kod: values?.Kod,
          tanim: values?.Tanim,
          renkKodu: requestBody?.RenkKodu,
          pasif: values?.Pasif,
        };

        setTableData((pre) => [...pre, newItem]);
        validation.resetForm();

      }
      
      message.success("Kayıt Başarılı!");
      toggle();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Id: isEdit ? editData?.Id : null,
      Kod: isEdit ? editData?.kod : "",
      Tanim: isEdit ? editData?.tanim : "",
      RenkKodu: isEdit ? editData?.renkKodu : "",
      Pasif: isEdit ? editData?.pasif : false,
    },

    validationSchema: Yup.object({
      Kod: Yup.string().required("Bu alan zorunlu!"),
      Tanim: Yup.string().required("Bu alan zorunlu!"),
      RenkKodu: Yup.string().required("Bu alan zorunlu!"),
    }),

    onSubmit: handleFormSubmit,
  });

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => {
        if (loading) return;
        toggle();
      }}
    >
      <ModalHeader
        className="p-3"
        toggle={() => {
          if (loading) return;
          toggle();
        }}
      >
        Renk {isEdit ? "Tanımını Düzenle" : "Tanımı Ekle"}
      </ModalHeader>
      <ModalBody>
        {errorMessage ? <Alert color="danger"> {errorMessage} </Alert> : null}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3">
            <label htmlFor="Tanim" className="form-label">
              Renk Tanım
            </label>
            <Input
              id="Tanim"
              name="Tanim"
              type="text"
              className="form-control"
              placeholder="Renk tanımı giriniz.."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Tanim}
              invalid={validation.touched.Tanim && validation.errors.Tanim ? true : false}
            />
            {validation.touched.Tanim && validation.errors.Tanim ? (
              <FormFeedback type="invalid">{validation.errors.Tanim}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="Kod" className="form-label">
              Renk Kod
            </label>
            <Input
              id="Kod"
              name="Kod"
              type="text"
              className="form-control"
              placeholder="Renk kodu giriniz.."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Kod}
              invalid={validation.touched.Kod && validation.errors.Kod ? true : false}
            />
            {validation.touched.Kod && validation.errors.Kod ? (
              <FormFeedback type="invalid">{validation.errors.Kod}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="RenkKodu" className="form-label">
              Pantone No
            </label>
            <Input
              id="RenkKodu"
              name="RenkKodu"
              type="color"
              className="form-control form-control-color w-100"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.RenkKodu || ""}
              invalid={validation.touched.RenkKodu && validation.errors.RenkKodu ? true : false}
            />

            {validation.touched.RenkKodu && validation.errors.RenkKodu ? (
              <FormFeedback type="invalid">{validation.errors.RenkKodu}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3 form-check">
            <label htmlFor="Pasif" className="form-label">
              Pasif
            </label>
            <Input
              id="Pasif"
              name="Pasif"
              type="checkbox"
              className="form-check-input"
              onChange={validation.handleChange}
              value={validation.values.Pasif}
            />
          </div>
          <div className="text-end">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              Kaydet
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default RecordRenkTanimi;
