import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";

import HeaderOlcumSayfasi from "./HeaderOlcumSayfasi";
import useErrorMessages from "../../../Components/Hooks/ErrorMessageHooks";

import { useStoreOlcuGiris } from "./hooks/useStore";
import { getOlcumDataSayfa, postOlcumKaydet } from "../../../helpers/api_requests";

const key = "message";

const initialCardData = {
  title: null,
  renk: null,
  beden: null,
  kontrolEdilecekOlcuSayisi: 0,
  kontrolEdilenOlcuSayisi: 0,
  olcu: 0,
  toleransArti: 0,
  toleransEksi: 0,
};

const TabOlcumSayfasi = ({ toggleTabId, activeTabId, tabId }) => {
  const {
    dispatch,
    uretimEmirId,
    modelOlcumId,
    secimYapilanOlculer,
    renk,
    renkId,
    surekliRenk,
    beden,
    bedenId,
    surekliBeden,
    kontrolSayisi,
    visibleHeaderOlcumSayfasi,
    setStoreKontrolSayisi,
    setStoreActiveOlcuId,
    setStoreOlcumDataSayfa,
    setStoreCounterStatus,
  } = useStoreOlcuGiris();

  const inputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [olcumState, setOlcumState] = useState("");
  const [inputStatus, setInputStatus] = useState(null);
  const [counter, setCounter] = useState(0);
  const [errorInputCounter, setErrorInputCounter] = useState(1);
  const [cardData, setCardData] = useState(initialCardData);
  const [UUID, setUUID] = useState(null);
  const [olcumDataSayfa, setOlcumDataSayfa] = useState(null);

  const [stepTime, setStepTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isStepTimeActive, setIsStepTimeActive] = useState(false);
  const [isTotalTimeActive, setIsTotalTimeActive] = useState(false);

  const errorMessage = useErrorMessages(error);

  useEffect(() => {
    if (activeTabId === tabId) {
      if (stepTime === 0) {
        startStepTimer();
      }

      if (totalTime === 0) {
        startTotalTimer();
      }
    }
  }, [activeTabId, tabId, stepTime, totalTime]);

  useEffect(() => {
    if (activeTabId === tabId) {
      startComponent();
    }

    return () => {
      if (activeTabId !== tabId) {
        resetStates();
      }
    };
  }, [activeTabId, tabId, counter, olcumDataSayfa]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setError(null), 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    let stepInterval = null;

    if (isStepTimeActive) {
      stepInterval = setInterval(() => {
        setStepTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(stepInterval);
    }

    return () => clearInterval(stepInterval);
  }, [isStepTimeActive, stepTime]);

  useEffect(() => {
    let totalInterval = null;

    if (isTotalTimeActive) {
      totalInterval = setInterval(() => {
        setTotalTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(totalInterval);
    }

    return () => clearInterval(totalInterval);
  }, [isTotalTimeActive, totalTime]);

  async function startComponent() {
    if (!olcumDataSayfa) {
      await fetchOlcumDataSayfa();
    }

    if (counter === 0) {
      generateUUID();

      if (totalTime) {
        stopTotalTimer();
      }

      if (stepTime) {
        stopStepTimer();
      }
    }

    editCardData();
  }

  async function fetchOlcumDataSayfa() {
    try {
      setLoading(true);
      const responseData = await getOlcumDataSayfa(uretimEmirId, modelOlcumId, bedenId);
      const tamamlananKontrolSayisi = responseData?.tamamlananKontrolSayisi || 0;
      dispatch(setStoreKontrolSayisi(tamamlananKontrolSayisi));
      dispatch(setStoreOlcumDataSayfa(responseData));
      setOlcumDataSayfa(responseData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function olcuBilgiKaydet(olcum, isCorrectValue) {
    try {
      setLoading(true);
      message.loading({ content: "Kayıt yapılıyor..", duration: 0, key });

      const olcuId = secimYapilanOlculer[counter]?.id;

      const requestBody = {
        Guid: UUID,
        UretimEmirId: uretimEmirId,
        RenkTanimId: renkId,
        BedenTanimId: bedenId,
        ModelKartOlcuTablosuId: olcuId,
        HedefOlcu: cardData.olcu,
        GerceklesenOlcu: olcum,
        KontrolSayisi: errorInputCounter,
        Hatali: !isCorrectValue,
      };

      // console.log("Ölçü Süresi: ", stepTime);
      // console.log("Toplam Süre: ", totalTime);

      await postOlcumKaydet(requestBody);

      if (isCorrectValue) {
        setErrorInputCounter(1);
        setCounter((pre) => pre + 1);
        setInputStatus(null);
      }

      setOlcumState("");

      message[`${isCorrectValue ? "success" : "info"}`]({
        content: isCorrectValue ? "Kayıt Başarılı!" : "Hatalı Giriş Kaydı Başarılı!",
        key,
      });
    } catch (error) {
      setError(error);
      message.error({ content: "Kayıt Gerçekleştirilemedi!", key });
    } finally {
      setLoading(false);
    }
  }

  async function handleKeyDownInput(event) {
    if (event.key === "Enter") {
      const inputValue = event.target?.value;

      const maxValue = cardData.olcu + cardData.toleransArti;
      const minValue = cardData.olcu - cardData.toleransEksi;

      let isCorrectValue = null;
      const isInputWithinRange = inputValue >= minValue && inputValue <= maxValue;

      const counterStatus = {
        isDownCounter: false,
        isCounter: false,
        isUpCounter: false,
      };

      if (isInputWithinRange) {
        dispatch(setStoreCounterStatus({ ...counterStatus, isCounter: true }));
        isCorrectValue = true;
      } else {
        isCorrectValue = false;

        const maxHataliGiris = olcumDataSayfa?.hataTekrarOlcumSayisi;

        if (errorInputCounter >= maxHataliGiris) {
          isCorrectValue = true;

          if (inputValue > maxValue) {
            dispatch(setStoreCounterStatus({ ...counterStatus, isUpCounter: true }));
          }

          if (inputValue < minValue) {
            dispatch(setStoreCounterStatus({ ...counterStatus, isDownCounter: true }));
          }
        } else {
          notify("error", "Ölçüm Dışı Değer!");
          setErrorInputCounter((pre) => pre + 1);
        }
      }

      stopStepTimer();
      setInputStatus(isCorrectValue);

      const olcum = Number(inputValue) || 0;
      await olcuBilgiKaydet(olcum, isCorrectValue);
    }
  }

  function handleChangeInput(e) {
    const inputValue = e.target?.value;
    setOlcumState(inputValue);
  }

  function generateUUID() {
    const UUID = uuidv4().replace(/-/g, "").substring(0, 10);
    setUUID(UUID);
  }

  function editCardData() {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const title = secimYapilanOlculer[counter]?.olcuAdi || "Tanımsız Ölçü Adı!";
    const toleransArti = secimYapilanOlculer[counter]?.toleransArti || 0;
    const toleransEksi = secimYapilanOlculer[counter]?.toleransEksi || 0;

    const olcuId = secimYapilanOlculer[counter]?.id;
    const aramaYapilacakBedenArr = olcumDataSayfa?.lstBedenBilgi || [];

    const olcu = aramaYapilacakBedenArr.find((item) => item?.olcuTanimId === olcuId)?.olcuBilgi || 0;

    dispatch(setStoreActiveOlcuId(olcuId));

    setCardData((pre) => ({
      ...pre,
      olcu,
      title,
      toleransArti,
      toleransEksi,
      renk: renk || "TANIMSIZ",
      beden: beden || "TANIMSIZ",
      kontrolEdilecekOlcuSayisi: secimYapilanOlculer?.length || 0,
      kontrolEdilenOlcuSayisi: counter,
    }));

    const maxCounterValue = secimYapilanOlculer.length;

    if (counter >= maxCounterValue) {
      dispatch(setStoreKontrolSayisi(kontrolSayisi + 1));

      setCounter(0);

      if (surekliRenk && !surekliBeden) {
        toggleTabId(activeTabId - 1);
        return;
      }

      if ((surekliBeden && !surekliRenk) || (!surekliRenk && !surekliBeden)) {
        toggleTabId(activeTabId - 2);
        return;
      }
    }
  }

  function resetStates() {
    setOlcumState("");
    setInputStatus(null);
    setCounter(0);
    setErrorInputCounter(1);
    setCardData(initialCardData);
  }

  const notify = (type, content) =>
    toast(content, {
      type: type,
      position: "top-right",
      theme: "colored",
      hideProgressBar: false,
      progressClassName: "mt-4",
    });

  const startStepTimer = () => {
    setIsStepTimeActive(true);
  };

  const stopStepTimer = () => {
    setIsStepTimeActive(false);
    setStepTime(0);
  };

  const startTotalTimer = () => {
    setIsTotalTimeActive(true);
  };

  const stopTotalTimer = () => {
    setIsTotalTimeActive(false);
    setTotalTime(0);
  };

  const formatTimeWithUnit = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    if (minutes > 0) {
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")} dakika`;
    } else {
      return `${String(seconds).padStart(2, "0")} saniye`;
    }
  };

  return (
    <div className="row">
      {visibleHeaderOlcumSayfasi ? (
        <div className="col-12">
          <HeaderOlcumSayfasi totalTime={formatTimeWithUnit(totalTime)} />
        </div>
      ) : null}

      <div className="col-12">
        <Card>
          <ToastContainer />

          <CardHeader className="fs-3 fw-medium bg-gradient bg-primary text-white text-center text-uppercase">
            {cardData.title}
          </CardHeader>

          <CardBody className="fs-5 bg-light rounded">
            {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

            <Row className="my-3 text-center">
              <Col xs={6} lg={3}>
                <h4 className="text-primary fw-bold">ÖLÇÜ</h4>
                <p className="fs-2">{cardData.olcu}</p>
              </Col>
              <Col xs={6} lg={3}>
                <h4 className="text-primary fw-bold">TOLERANS +</h4>
                <p className="fs-2">{cardData.toleransArti}</p>
              </Col>
              <Col xs={6} lg={3}>
                <h4 className="text-primary fw-bold">TOLERANS -</h4>
                <p className="fs-2">{cardData.toleransEksi}</p>
              </Col>
              <Col xs={6} lg={3}>
                <h4 className="text-primary fw-bold">ÖLÇÜM</h4>
                <Input
                  id="olcum"
                  type="number"
                  ref={inputRef}
                  value={olcumState}
                  onChange={handleChangeInput}
                  onKeyDown={handleKeyDownInput}
                  className={`form-control form-control-lg fw-bold ${
                    inputStatus !== null ? `text-white bg-${inputStatus ? "success" : "danger"}` : ""
                  } `}
                />
              </Col>
            </Row>
          
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default TabOlcumSayfasi;
