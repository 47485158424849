import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  headerOlcumSayfasi: {
    visible: false,
  },
  sidebarOlcumSayfasi: {
    visible: false,
    data_Card1: {
      olcuListesi: [],
    },
    data_Card2: {},
    data_Card3: {},
  },
  tabs: {
    tab_modelSecim: {
      uretimEmirId: null,
    },
    tab_olcuSecim: {
      modelOlcumId: null,
    },
    tab_olcuTipSecim: {
      olcuTipId: null,
      olcuTipi: null,
    },
    tab_olcuTabloSecim: {
      secimYapilanOlculer: [],
    },
    tab_renkSecim: {
      surekli: true,
      renkId: null,
      renk: null,
    },
    tab_bedenSecim: {
      surekli: true,
      bedenId: null,
      beden: null,
    },
    tab_olcumSayfasi: {
      kontrolSayisi: 0,
      activeOlcuId: null,
      olcumDataSayfa: null,
      isDownCounter: false,
      isCounter: false,
      isUpCounter: false,
    },
  },
};

const olcuGirisSlice = createSlice({
  name: "Ölçü Giriş",
  initialState,
  reducers: {
    setStoreOlcuListesi(store, action) {
      store.sidebarOlcumSayfasi.data_Card1.olcuListesi = action.payload;
    },
    setStoreCounterStatus(store, action) {
      store.tabs.tab_olcumSayfasi.isDownCounter = action.payload.isDownCounter;
      store.tabs.tab_olcumSayfasi.isCounter = action.payload.isCounter;
      store.tabs.tab_olcumSayfasi.isUpCounter = action.payload.isUpCounter;
    },
    setStoreOlcuTipSecim(store, action) {
      store.tabs.tab_olcuTipSecim.olcuTipId = action.payload.id;
      store.tabs.tab_olcuTipSecim.olcuTipi = action.payload.tanim;
    },
    setStoreOlcumDataSayfa(store, action) {
      store.tabs.tab_olcumSayfasi.olcumDataSayfa = action.payload;
    },
    setStoreActiveOlcuId(store, action) {
      store.tabs.tab_olcumSayfasi.activeOlcuId = action.payload;
    },
    setStoreSidebarOlcumSayfasiVisible(store, action) {
      store.sidebarOlcumSayfasi.visible = action.payload;
    },
    setStoreHeaderOlcumSayfasiVisible(store, action) {
      store.headerOlcumSayfasi.visible = action.payload;
    },
    setStoreUretimEmirId(state, action) {
      state.tabs.tab_modelSecim.uretimEmirId = action.payload;
    },
    setStoreModelOlcumId(state, action) {
      state.tabs.tab_olcuSecim.modelOlcumId = action.payload;
    },
    setStoreSecimYapilanOlculer(state, action) {
      state.tabs.tab_olcuTabloSecim.secimYapilanOlculer = action.payload;
    },
    setStoreRenk(state, action) {
      state.tabs.tab_renkSecim.renkId = action.payload.renkId;
      state.tabs.tab_renkSecim.renk = action.payload.renk;
    },
    setStoreBeden(state, action) {
      state.tabs.tab_bedenSecim.bedenId = action.payload.bedenId;
      state.tabs.tab_bedenSecim.beden = action.payload.beden;
    },
    setStoreSurekliStatusTabRenkSecim(state, action) {
      state.tabs.tab_renkSecim.surekli = action.payload;
    },
    setStoreSurekliStatusTabBedenSecim(state, action) {
      state.tabs.tab_bedenSecim.surekli = action.payload;
    },
    setStoreKontrolSayisi(state, action) {
      state.tabs.tab_olcumSayfasi.kontrolSayisi = action.payload;
    },
    resetStore() {
      return { ...initialState };
    },
  },
});

export const {
  setStoreOlcuListesi,
  setStoreCounterStatus,
  setStoreHeaderOlcumSayfasiVisible,
  setStoreSidebarOlcumSayfasiVisible,
  setStoreUretimEmirId,
  setStoreModelOlcumId,
  setStoreOlcuTipSecim,
  setStoreSecimYapilanOlculer,
  setStoreRenk,
  setStoreBeden,
  setStoreSurekliStatusTabRenkSecim,
  setStoreSurekliStatusTabBedenSecim,
  setStoreKontrolSayisi,
  setStoreActiveOlcuId,
  setStoreOlcumDataSayfa,
  resetStore,
} = olcuGirisSlice.actions;

export default olcuGirisSlice.reducer;
